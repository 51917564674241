











































































































import Vue from 'vue'
import EditCell from '../../components/EditCell.vue'

export default Vue.extend({
  data() {
    return {
      loading: true,
      paginate: {
        keywords: '',
        page: 1,
        size: 10,
        total: 0,
        status: '',
        startTime: '',
        endTime: '',
        orderBy: {},
      },
      entities: [],
      selection: [],
    }
  },
  created() {
    this.fetch()
  },
  components: {
    EditCell,
  },
  methods: {
    async fetch() {
      this.loading = true
      const res = await this.$http.request({
        url: 'ar_orders',
        method: 'get',
        params: this.paginate,
      })
      this.entities = res.data.data
      this.loading = false
      this.paginate.total = res.data.meta.total
    },
    resetSearch() {
      this.paginate.keywords = ''
      this.paginate.page = 1
      this.paginate.status = ''
      this.paginate.startTime = ''
      this.paginate.endTime = ''
      this.fetch()
    },
    handleSizeChange(size: number) {
      this.paginate.size = size
      this.fetch()
    },
    handleCurrentChange(page: number) {
      this.paginate.page = page
      this.fetch()
    },
    handleSortChange({ prop, order }: { prop: any; order: any }) {
      if (order) {
        this.paginate.orderBy = {
          prop,
          order: order.replace('ending', ''),
        }
      } else {
        this.paginate.orderBy = {}
      }
      this.fetch()
    },
    handleSelectionChange(val: any) {
      this.selection = val.map((i: any) => i.id)
    },
    getStatus(status: number) {
      switch (status) {
        case 0:
          return '激活中'
        case 1:
          return '待付款'
        case 2:
          return '失败'
        case 3:
          return '成功'
        default:
          return ''
      }
    },
    // 完成充值
    async handleCompelete(row: any) {
      const ok = await this.$confirm(`确定要完成该订单吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
      if (ok) {
        const result: any = await this.$prompt('请输入支付人', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        })

        const operator = result?.value?.trim()
        if (!operator) {
          return this.$message.error('请输入支付人')
        }

        try {
          this.loading = true
          await this.$http.put(`ar_orders/complete/${row.id}`, { operator })
          this.$message.success('完成成功')
          this.fetch()
        } finally {
          this.loading = false
        }
      }
    },
    // 还原
    async handleResetCode(order: any) {
      const ok = await this.$confirm(
        `确定要还原激活码：${order.code}吗?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        },
      )
      if (ok) {
        try {
          this.loading = true
          await this.$http.put(`codes/reset/${order.code}`)
          this.$message.success('还原成功')
          this.fetch()
        } finally {
          this.loading = false
        }
      }
    },
    // 修改钱包
    async editWallet(id: number, wallet: any) {
      try {
        this.loading = true
        await this.$http.put(`ar_orders/${id}`, { wallet })
        this.$message.success('编辑成功')
      } finally {
        this.fetch()
      }
    },
    // 批量完成
    async groupComplete() {
      if (!this.selection?.length) {
        return this.$message.error('请先选择要完成的订单')
      }

      const result: any = await this.$prompt('请输入支付人', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })

      const operator = result?.value?.trim()
      if (!operator) {
        return
      }

      try {
        this.loading = true
        await this.$http.post('ar_orders/group_complete', {
          selection: this.selection,
          operator,
        })
        this.$message.success('批量完成成功')
        this.fetch()
      } finally {
        this.loading = false
      }
    },
    async exportOrder() {
      const response = await this.$http.post(
        'ar_orders/export',
        this.paginate,
        {
          responseType: 'arraybuffer',
        },
      )
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
      let downloadElement = document.createElement('a')
      let href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `table.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    },
  },
})
